import {graphql, useStaticQuery} from "gatsby"
import Link from "gatsby-link"
import React, {useState} from "react"
import ReactHtmlParser from "react-html-parser"
import {Footer} from "../components/footer/Footer.js"
import {Header} from "../components/header/Header.js"
import SearchForm from "../components/searchForm/SearchForm.js"
import "../styles/marques.css"
import {filterDefaultValue} from "../utils/common.js"
import {useFilterAndRangeDataByStartingLetter} from "../utils/hooks.js"
import AlphabeticalFilter from "./filters/AlphabeticalFilter.js"
import {Helmet} from "react-helmet"
import BrandsListItem from "./brandsListItem/BrandsListItem.js"

const MarquesPage = () => {
    const [search, setSearch] = useState("")
    const [selectedItem, setSelectedItem] = useState(filterDefaultValue.ALL)

    const alpha = Array.from(Array(26)).map((e, i) => i + 65)

    const filters = [filterDefaultValue.ALL, "0-9"].concat(
        alpha.map((x) => String.fromCharCode(x))
    )

    const updateSearch = (e) => {
        //console.log(e)
        setSearch(e.target.value)
        // console.log(search);
    }

    function isBrand(obj) {
        return !obj.name.includes("00_LOGO") && !obj.type
    }

    const marquesdata = useStaticQuery(graphql`
    query {
      allWpPage {
        edges {
          node {
            title
            content
          }
        }
      }
      allBrandS3(sort: { fields: hit___name, order: [ASC] }) {
        edges {
          node {
            hit {
              name
              type
            }
          }
        }
      }
    }
  `)
    const marquesS3 = marquesdata.allBrandS3.edges
    const filtered =
        useFilterAndRangeDataByStartingLetter({
            data: marquesS3,
            filters,
        }) ?? []

    function correctLogoName(marque) {
        marque = marque.toLowerCase()
        marque = marque.split(" ").join("_")
        return marque
    }

    function generateSlug(item) {
        item = item.toLowerCase()
        item = item.split(" ").join("-")
        return item
    }

    function correctFolderName(marque) {
        marque = marque.toUpperCase()
        marque = marque.split(" ").join("+")
        return marque
    }

    function changeImageUrlToThumb(url) {
        if (url.includes(".jpg")) {
            url = url.replace(".jpg", "-thumb.jpg")
        } else if (url.includes(".png")) {
            url = url.replace(".png", "-thumb.png")
        }
        return url
    }

    //changeTheURL("nicotra")

    function changeTheURL(name) {
        let url = typeof window !== "undefined" ? window.location.origin : ""
        let correctName = name

        url = url.replace("brand.", "")
        url = url.replace("contact.", "")
        url = url.replace("telechargements.", "")

        if (url.includes("www.")) {
            if (url.includes("http://")) {
                url = url.replace("http://www.", "http://www." + correctName + ".")
            } else {
                url = url.replace("https://www.", "http://www." + correctName + ".")
            }
        } else {
            if (url.includes("http://")) {
                url = url.replace("http://", "http://" + correctName + ".")
            } else {
                url = url.replace("https://", "http://" + correctName + ".")
            }
        }

        return url
    }

    let marque_logo_url = ""

    const selectedElements =
        filtered[filtered.findIndex((i) => Object.keys(i)[0] === selectedItem)][
            selectedItem
            ]

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Les Marques</title>
                <meta property="og:locale" content="fr_FR"/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content={`Les Marques`}/>

                <meta
                    name="description"
                    content={`Contient les les marques disponibles`}
                />

                <meta
                    property="og:description"
                    content={`Contient les les marques disponibles`}
                />
                <meta
                    property="og:url"
                    content={`https://brand.setem.fr/les-marques/`}
                />
                <meta property="og:site_name" content="SETEM"/>
            </Helmet>

            <Header/>

            <div className="bannMarqueBox">
                <div
                    className="bannBox"
                    data-sizes="(max-aspect-ratio: 2550/770) 331vh"
                >
                    <SearchForm {...{search, updateSearch, to: "/searchpage"}} title="Les marques"/>
                </div>
            </div>

            <div className="marques-main-box">
                <div className="marques-pres-box">
                    <div className="pres-elements-box">
                        <p className="text-pres">
                            {marquesdata.allWpPage.edges.map((page, index) => {
                                if (page.node.title === "Les marques") {
                                    //return ReactHtmlParser(page.node.content)
                                    const textMarqueIntroduction = `Retrouvez <label style="color: red;font-size: 20px">${(marquesS3) ? marquesS3.length : 0}</label>  marques chez S.E.T.E.M, spécialiste de la fourniture de matériel industriel .`;
                                    return ReactHtmlParser(textMarqueIntroduction)
                                }
                            })}
                        </p>
                    </div>
                </div>


                <div className="filterAndResultContainer">
                    <AlphabeticalFilter
                        {...{
                            filters: filtered,
                            selectedItem,
                            onclickItem: (e) => {
                                e.preventDefault()
                                setSelectedItem(e.currentTarget.textContent)
                            },
                        }}
                    />
                </div>

                <div className="marques-grid marques-main">
                    {selectedElements.length ? (
                        selectedElements.map((marque, index) => {
                            //console.log(marque)

                            marque_logo_url =
                                "https://media-setem.s3.eu-central-1.amazonaws.com/articles/" +
                                correctFolderName(marque.node.hit.name) +
                                "/00_LOGO-ETIQUETTES/marque_logo_" +
                                correctLogoName(marque.node.hit.name) +
                                ".png"
                            return marque && marque.node && !isBrand(marque.node.hit) ? (
                                <BrandsListItem
                                    {...{
                                        marque,
                                        to: `https://${generateSlug(marque.node.hit.name)}.${
                                            process.env.GATSBY_CURRENT_URL
                                        }/${generateSlug(marque.node.hit.name)}`,
                                        imgUrl: changeImageUrlToThumb(marque_logo_url),
                                    }}
                                />
                            ) : null
                        })
                    ) : (
                        <>Aucun élément à afficher</>
                    )}
                </div>
                <div className="filterAndResultContainer">
                    <AlphabeticalFilter
                        {...{
                            filters: filtered,
                            selectedItem,
                            onclickItem: (e) => {
                                e.preventDefault()
                                setSelectedItem(e.currentTarget.textContent)
                            },
                        }}
                    />
                </div>
                {/* <div className="containPictoBox">
                    <MediaPicto/>
                </div>*/}
            </div>
            <Footer/>
        </div>
    )
}
export default MarquesPage
