import { useToken } from "@chakra-ui/react"
import { useThemeOptions } from "@gatsbywpthemes/gatsby-theme-blog-data/src/hooks"
import { filterDefaultValue } from "./common"

const useLayoutWidth = () => {
  const { layoutWidth } = useThemeOptions()
  const [xl, lg, md, sm] = useToken("sizes", [
    "container.xl",
    "container.lg",
    "container.md",
    "container.sm",
  ])

  function getLayoutWidth(layoutType) {
    switch (layoutWidth[layoutType]) {
      case "xl":
        return xl
      case "md":
        return md
      case "lg":
        return lg
      case "sm":
        return sm
      default:
        return "1280px"
    }
  }

  return [getLayoutWidth, xl, lg, md, sm]
}

const useLayoutStyles = (layoutType, templateName) => {
  const {
    widgetAreas: { sidebarWidgets },
  } = useThemeOptions()
  const pageTemplate = templateName?.toLowerCase()
  const sidebarPage = pageTemplate.includes("sidebar")
  const [getLayoutWidth, xl] = useLayoutWidth()

  const containerStyles =
    sidebarWidgets && sidebarPage
      ? {
          maxWidth: xl,
          ".entry": {
            width: { base: "100%", lg: "67%", xl: "70%" },
          },
          ".sidebar": { width: { base: "100%", lg: "33%", xl: "30%" } },
        }
      : { maxWidth: getLayoutWidth(layoutType) }

  const sidebarSide = sidebarPage
    ? pageTemplate === `left sidebar`
      ? {
          flexDirection: `row-reverse`,
          ".entry": { pl: { base: 0, lg: 8 } },
        }
      : pageTemplate === `right sidebar`
      ? { ".entry": { pr: { base: 0, lg: 8 } } }
      : ""
    : { display: "block" }

  return {
    containerStyles,
    sidebarSide,
    sidebarPage,
    sidebarWidgets,
    getLayoutWidth,
  }
}

const filterDataByStartingLetter = ({ data, startingLetter }) => {
  return (
    data?.filter((item) => {
      if (startingLetter === filterDefaultValue.ALL) return true
      const element = item.node.hit.name
      if (startingLetter === "0-9")
        return [...Array(10).keys()]
          .map((i) => i.toString())
          .includes(element.charAt(0))
      return element.startsWith(startingLetter)
    }) ?? []
  )
}

const removeUsedData = (originalArray, usedData) => {
  usedData.map((i) => originalArray.splice(originalArray.indexOf(i), 1))

  return originalArray
}

const useFilterAndRangeDataByStartingLetter = ({ data, filters }) => {
  let brands = data.map((i) => i)
  const filteredBrands = []
  filters.map((filter) => {
    const correspondingData = filterDataByStartingLetter({
      data: brands,
      startingLetter: filter,
    })

    filteredBrands.push({ [filter]: correspondingData })
    brands =
      filter !== filterDefaultValue.ALL && correspondingData.length
        ? removeUsedData(brands, correspondingData)
        : brands
  })
  return filteredBrands
}

export {
  useLayoutStyles,
  useLayoutWidth,
  useFilterAndRangeDataByStartingLetter,
}
