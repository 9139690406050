import React from "react"
import PropTypes from "prop-types"
import "./alphabeticalFilter.css"
const AlphabeticalFilter = ({ filters, onclickItem, selectedItem }) => {
  return (
    <div className="filterContainer">
      {filters &&
        filters.map((filter, index) => {
          const key = Object.keys(filter)[0]
          const isEmpty = !filter[key].length
          return (
            <div
              {...{
                className: `alpha ${selectedItem === key ? "selected" : ""} ${
                  !isEmpty ? "blue" : "gray"
                } ${
                  filters.indexOf(filter) === filters.length - 1
                    ? "ln-last"
                    : ""
                }`,
                onClick: onclickItem,
                key,
              }}
            >
              {key}
            </div>
          )
        })}
    </div>
  )
}

AlphabeticalFilter.propTypes = {
  filters: PropTypes.array.isRequired,
  onclickItem: PropTypes.func,
  selectedItem: PropTypes.number,
}

export default AlphabeticalFilter
