import React from "react"
import PropTypes from "prop-types"
import Link from "gatsby-link"

const SearchForm = (props) => {
    const {search, updateSearch, to} = props
    const customSpanStyles = {
        bottom: "18px",
    }

    const videoStyle = {
        position: "absolute",
        //top: "-110px",
        objectFit: "cover",
        height: "100%",
        width: "100%",
        top: "0",
        left: "0",
    }
    return (
        <div className="search-contain desk-absolute">
            <video src={"https://setem.fr/wp-content/uploads/2022/06/video-setem.mp4"} loop={true} autoPlay={true}
                   muted={true} playsInline={true} height={"1000px"} width={"1778px"} style={videoStyle}></video>
            <div className="search-container-bandeau">
                {(props)?<h1 className="title-bandeau uk-font-secondary">{props.title}</h1>:""}

                <form className="search-form-marque"
                      style={{position: "relative", paddingTop: "0px", marginLeft: "0px"}}>
                    <div>
                        <input
                            id="search-zone"
                            className="search"
                            type="text"
                            placeholder="Rechercher un produit"
                            size="30"
                            value={search}
                            onChange={updateSearch}
                        />
                        <Link className="loupeIcon" to={to} state={{searchField: search}}>
                            <button type="submit" >
              <span style={customSpanStyles}>
                <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                      fill="none"
                      stroke="#000"
                      stroke-width="1.1"
                      cx="9"
                      cy="9"
                      r="7"
                  ></circle>
                  <path
                      fill="none"
                      stroke="#000"
                      stroke-width="1.1"
                      d="M14,14 L18,18 L14,14 Z"
                  ></path>
                </svg>
              </span>
                            </button>
                        </Link>
                    </div>
                </form>
            </div>
            Ω
        </div>
    )
}

SearchForm.propTypes = {
    search: PropTypes.string.isRequired,
    updateSearch: PropTypes.func.isRequired,
    to: PropTypes.string.isRequired,
}

export default SearchForm
