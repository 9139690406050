import ImageNotFound from "../assets/img/image-not-found.png"

export const handleImageError = ({ currentTarget }) => {
  currentTarget.onerror = null // prevents looping
  currentTarget.src = ImageNotFound
}

export const detectAsterisk = (value, top) => {
  return `<span>${value.replace(
    /\*/g,
    `<sup style='top: ${top ?? 0}px;font-size: 17px;'>*</sup>`
  )}</span>`
}

export const filterDefaultValue = {
  ALL: "All",
  Numerical: "0-9",
}
