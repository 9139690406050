import Link from "gatsby-link"
import Proptypes from "prop-types"
import React from "react"
import "../../styles/marques.css"

const BrandsListItem = ({ marque, to, imgUrl }) => {
  return (
    <div className="marques-content marques-content-update">
      <div className="marque-url-img container-img">
        {/*<img className="marque-img" src={item.url} />*/}
        <Link {...{ className: "marques", to }}>
          <img
            src={imgUrl}
            className="logo-img"
            alt={
              marque?.node?.hit?.name
                ? marque.node.hit.name.replaceAll("_", " ")
                : ""
            }
          />
        </Link>
      </div>
      <div className="marque-titre-img marque-title-img-update">
        <Link {...{ className: "marques", to }}>
          <span className="img-titre">
            {marque?.node?.hit?.name
              ? marque.node.hit.name.replaceAll("_", " ")
              : ""}
          </span>
        </Link>
      </div>
    </div>
  )
}

BrandsListItem.propTypes = {
  marque: Proptypes.object.isRequired,
  to: Proptypes.string.isRequired,
  imgUrl: Proptypes.string.isRequired,
}

export default BrandsListItem
